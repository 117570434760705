import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { LoginHttpService } from './services';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ICondominio, IUsuario, condominioActions, usuarioActions, routeActions, IRoute } from '../../store';
import { Router } from '@angular/router';

// Using SweetAlert2
// https://themesbrand.com/velzon/docs/angular/sweetalert2.html

@Component({
  selector: 'login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SweetAlert2Module
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, OnDestroy {
  public form: any;
  public mensagem: string = '';
  private subs: Subscription[] = [];

  store = inject(Store);
  formBuilder = inject(FormBuilder);
  loginHttpService = inject(LoginHttpService);
  router = inject(Router);

  ngOnInit(): void {
    this.criarForm();
  }

  ngOnDestroy(): void {
    try {
      this.subs.map(s => s.unsubscribe())
    } catch (e) {
      console.error(e);
    }
  }

  private criarForm() {
    this.form = this.formBuilder.group({
      email: [''],
      password: ['']
    });
  }

  public login() {
    this.subs.push(this.loginHttpService.login(this.form.get('email').value, this.form.get('password').value).subscribe({
      next: res => {
        let usuario = <IUsuario>{
          id: res.usuario.id,
          nome: res.usuario.nome,
          email: res.usuario.email,
          id_cliente: res.usuario.id_cliente,
          token: res.token
        }
        let condominios = <ICondominio[]>res.condominios.map((c: any) => {
          return {
            id: c.id,
            cnpj: c.cnpj,
            id_cliente: c.id_cliente,
            nome_fantasia: c.nome_fantasia,
            razao_social: c.razao_social
          }
        })
        let selecionado = condominios.length == 1 ? condominios[0] : <ICondominio>{};
        
        this.store.dispatch(usuarioActions.setUsuario(usuario));
        this.store.dispatch(condominioActions.setCondominios({ condominios, selecionado }));

        this.router.navigate(['home']);
      },
      error: () => {
        Swal.fire({
          title: 'Oops..',
          text: 'Credencial inválida! Tente novamente.',
          icon: 'warning',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary px-4'
          },
          buttonsStyling: false,
        });
      }
    }));
  }
}
